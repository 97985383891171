/** Pinepointers 5: Now **/

import React from "react";
import { preloadImages, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import gsap from "gsap";

let globalState, data, preloadData;

class PinepointersPage5 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[5];
        preloadData = globalState.assets[6];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        console.log('page 5 mounted');

        //position text
        positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
        positionText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
        gsap.to(this.refs.text2Ref, 0, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);
        
        //remove prev track
        globalState.baseAudioRef.updateTrack(2, false);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.badge, 
            globalState.cdnUrl + preloadData.badgeTop, 
            globalState.cdnUrl + preloadData.background,
            globalState.cdnUrl + preloadData.bgVideoPoster1,
            globalState.cdnUrl + preloadData.bgVideoPoster2,
            globalState.cdnUrl + preloadData.bgVideoPoster3
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        gsap.killTweensOf(this.refs.text2Ref);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
            showText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "left");
            hideText(this.refs.text2Ref, "right");
        }
    }

    isTransitioning = () => {

        //pause animations
    }


    render() {

        return (
            
            <>

                <link rel="prefetch" href="/pinepointers/6" />
                {preloadData && (
                    <>    
                    <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo1} />
                    <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo2} />
                    <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo3} />
                    </>
                )}

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/pinepointers/6" prevLocation="/pinepointers/4"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper" style={{ backgroundImage: `url(${globalState.cdnUrl + data.background})`, backgroundSize: "cover", backgroundPosition: "top center" }}>
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--left-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "2vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Now. Time passes and we become who we’ll be, defined by our jobs, our families, our accomplishments. Who we were is still a part of who we are, at least to those who knew us when." />
                        </div>
                        <div className="text text--right-top" ref="text2Ref">
                            <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "2vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="As we move on, lose touch, we’re sometimes happiest when we get some minor update about the people from our past. Enough to know that they landed on the other side. Enough to touch up our mental portrait of them, and forget about them until next time." />
                        </div>
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default PinepointersPage5
